<template>
    <div style="width:calc(100% - 20px);height: 740px;margin: 10px;">
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick1" style="height: 740px;border: none;">
            <el-tab-pane label="支付订单" name="first">
                <div style="width: 100%;height: 40px;margin-bottom: 10px;display: flex;">
                    <div style="width: 240px;height: 40px;">
                        <el-select v-model="value" clearable placeholder="产品名称">
                            <el-option v-for="(item,index) in tableData.nameList" :key="index" :label="item.name" :value="index">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="width: 240px;height: 40px;">
                        <el-input v-model="orderNo"  placeholder="订单号"></el-input>
                    </div>
                    <div style="width: 240px;height: 40px;margin-left: 15px;">
                        <el-date-picker
                            v-model="time"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="handleDateChange">
                        </el-date-picker>
                    </div>
                    <div style="width: 120px;height: 40px;margin-left: 130px;">
                        <el-select v-model="state" clearable  placeholder="支付状态">
                            <el-option v-for="(item,index) in tableData.payStatus"  :label="item.name" :value="index">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="width: 160px;line-height: 40px;margin-left: 20px;">
                        <el-button @click="seachOrderNo" type="primary">搜索</el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
                <el-table
                    :data="tableData.list"
                    style="width: 100%">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-form label-position="left" inline class="demo-table-expand">
                                <el-form-item label="商品名称">
                                    <span>{{ props.row.note }}</span>
                                </el-form-item>
                                <el-form-item label="支付银行">
                                    <span>{{ props.row.bankName }}</span>
                                </el-form-item>
                                <el-form-item label="支付金额">
                                    <span>{{ props.row.payAmt }}</span>
                                </el-form-item>
                                <el-form-item label="附加说明">
                                    <span>{{ props.row.description }}</span>
                                </el-form-item>
                                <el-form-item label="支付时间">
                                    <span>{{ props.row.createTime }}</span>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="订单号"
                        prop="orderNo"
                        width="200">
                    </el-table-column>
                    <el-table-column
                        label="商品名称"
                        prop="note">
                    </el-table-column>
                    <el-table-column
                        label="支付方式"
                        prop="bankName">
                    </el-table-column>
                    <el-table-column
                        label="支付金额(元)"
                        prop="payAmt">
                    </el-table-column>
                    <el-table-column
                        label="支付状态"
                        prop="status">
                    </el-table-column>
                    <el-table-column
                        label="支付时间"
                        prop="createTime">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <div style="display: flex; justify-content: flex-end; align-items: center; width: 100%; height: 40px; ">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="pageSize"
                @current-change="handleCurrentChange"
                :total="tableData.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeName: 'first',
            tableData: {},
            value: '',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            time: '',
            payType:0,
            orderNo:'',
            state:'',
            starTime:'',
            endTime:'',
            page:1,
            pageSize:11,
        };
    },

    created() {

    },

    mounted() {
        this.getPayOrderList();
    },
   methods: {
        //tab标签切换
       handleClick1(tab, event) {
            console.log(tab, event);
        },

       //请求数据
       getPayOrderList(){
           var that = this;
           var params = {};
           console.log(that.value)
           if(that.value  !== ''){
               params.payType = that.tableData.nameList[that.value].payType;
           }
           params.orderNo = that.orderNo;
           params.starTime = that.starTime;
           params.endTime = that.endTime;
           params.page = that.page;
           params.pageSize = that.pageSize;
           if(that.state !== ''){
               params.status = that.state;
           }

           that.newApi.getPayOrderList(params).then(function(res){
               if(res.isSuccess == 1){
                     that.tableData = res.data;
               }
           });
       },

       //获取页数
       handleCurrentChange(page) {
           var that = this;
           that.page = page;
           that.getPayOrderList();
       },

       //获取时间
       handleDateChange(){
           var that = this;
           if(that.time != null){
               that.starTime = that.utils.formatDate(that.time[0]);
               that.endTime = that.utils.formatDate(that.time[1]);
           }
       },

       //搜索
       seachOrderNo(){
           var that = this;
           that.getPayOrderList();
       },

       //重置
       reset(){
          var that = this;
          that.value = '';
          that.orderNo = '';
          that.time = '';
          that.payType = 0;
          that.page = 1;
          that.starTime = '';
          that.endTime = '';
          that.state = '';
          that.getPayOrderList();
       }
    }
};
</script>

<style>
.el-dialog__body {
    padding: 10px 5px;
}
.el-textarea__inner{
    height: 100px !important;
}
.el-radio{
    margin-top: 10px;
    margin-bottom: 10px;
}
.el-table th.el-table__cell {
    background-color: #EFF3F8; /* 设置背景颜色 */
}
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>